import React from 'react';
import ReactDOM from 'react-dom/client';
import styled from 'styled-components';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 1.5em;
  color: palevioletred;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const HeadWrapper = styled.section`
  height: 5vh;
  background: #E2EEE0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExplainWrapper = styled.section`
  height: 8vh;
  background: #E2EEE0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExplainTextWrapper = styled.section`
  width: 70vw;
  background: #E2EEE0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SurvayWrapper = styled.section`
  height: 77vh;
  background: #E2EEE0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FootWrapper = styled.section`
  height: 8vh;
  background: #E2EEE0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

root.render(
  <React.StrictMode>
    <HeadWrapper>
      <Title>
        위시폴 지원 페이지
      </Title>      
    </HeadWrapper>
    <ExplainWrapper>
      <ExplainTextWrapper>
        위시폴 서비스간 공유해주실 내용이 있으셨나요? 아래 폼을 통해 의견을 공유해주세요.
      </ExplainTextWrapper>
    </ExplainWrapper>
    <SurvayWrapper>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfnCreYTrIWBCgkjmSRyRYciBH7NyCD-f25PZwXSXKyzfF7NQ/viewform?https://docs.google.com/forms/d/e/1FAIpQLSfnCreYTrIWBCgkjmSRyRYciBH7NyCD-f25PZwXSXKyzfF7NQ/viewform?embedded=true" width= "100%" height= "100%" frameBorder="0">로드 중…</iframe>
    </SurvayWrapper>
    <FootWrapper>
      주식회사 유저커넥트
    </FootWrapper>
  </React.StrictMode>
);
